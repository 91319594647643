import React from 'react';

import * as s from '../../styles/agreement.module.scss';

const SberAgreementContent = () => {
  return (
    <>
      <h1 className={s.agreementTitle}>
        Согласие на обработку персональных данных
      </h1>
      <p className={s.agreementParagraph}>
        Нажимая кнопку «я согласен», я (далее – <b>субъект персональных данных</b>) даю конкретное, предметное, информированное, сознательное и однозначное согласие оператору персональных данных –  Обществу с ограниченной ответственностью «РДВ-Софт», юридический адрес - 109004, г. Москва, вн.тер.г. муниципальный округ Таганский, пер. Малый Дровяной, д. 3 стр. 1, помещ. II, ком. 32 (далее – <b>Общество</b>) на передачу моих персональных данных Публичному акционерному обществу «Сбербанк России» (юридический адрес - 117997, г. Москва, ул. Вавилова, д. 19, далее – <b>ПАО Сбербанк</b>) в целях рассмотрения моей кандидатуры для трудоустройства в ПАО Сбербанк, в том числе для учета в кадровом резерве.
      </p>
      <p className={s.agreementParagraph}>
        Мои персональные данные, в отношении которых дается данное согласие, включают следующие:
      </p>
      <ul className={s.agreementList}>
        <li>Фамилия, имя, отчество;</li>
        <li>Дата рождения;</li>
        <li>Место рождения</li>
        <li>Гражданство;</li>
        <li>Адрес регистрации;</li>
        <li>Адрес места жительства;</li>
        <li>Номер телефона;</li>
        <li>Адрес электронной почты и иные контактные данные;</li>
        <li>Сведения об образовании (наименование учебного заведения, форма и тип обучения, даты поступления и окончания, специальность и квалификация, номер диплома (свидетельства, аттестата и дата выдачи);</li>
        <li>Сведения о повышении квалификации и профессиональной переподготовке (включая серию, номер, дату выдачи документа о повышении квалификации или о переподготовке, наименование и местоположение образовательного учреждения, дата начала и завершения обучения, квалификация и специальность по окончании образовательного учреждения и другие сведения);</li>
        <li>Ученая степень / звание, дата присвоения учёной степени / звания;</li>
        <li>Награды и поощрения;</li>
        <li>Сведения о трудовом стаже и предыдущих местах работы;</li>
        <li>Иные данные, предоставленные мной самостоятельно, либо лицом, порекомендовавшим мою кандидатуру.</li>
      </ul>
      <p className={s.agreementParagraph}>
        Действия с моими персональными данными, выполняемые ПАО Сбербанк, включают: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, доступ персонала ПАО Сбербанк, передачу и предоставление Обществу с ограниченной ответственностью «САП СНГ», юридический адрес - 115054, г. Москва, Космодамианская наб. 52, стр.7, осуществляющему техническое сопровождение системы Success Factors и обрабатывающим персональные данные по поручению ПАО Сбербанк, а также иным лицам в случаях, предусмотренных законодательством Российской Федерации, блокирование, удаление, уничтожение персональных данных.
      </p>
      <p className={s.agreementParagraph}>
        Предусматривается смешанная обработка моих персональных данных – как без использования средств автоматизации, так и автоматизированная обработка с передачей полученной информации с использованием информационно-телекоммуникационной сети Интернет.
      </p>
      <p className={s.agreementParagraph}>
        Согласие дается на срок, необходимый для рассмотрения моей кандидатуры и принятия решения о приеме меня на работу в ПАО Сбербанк или, в случае принятия положительного решения о включении меня в кадровый резерв ПАО Сбербанк, на 5 лет. Общество и ПАО Сбербанк вправе продолжить обработку моих персональных данных по истечении указанного срока в случаях и в течение периода времени, предусмотренных законодательством Российской Федерации. 
      </p>
      <p className={s.agreementParagraph}>
        Мне известно, что я могу отозвать согласие на обработку персональных данных, указанных в настоящем согласии, подав Обществу и ПАО Сбербанк заявление в произвольной форме. В этом случае в отношении меня не сможет быть принято решение о приёме на работу в ПАО Сбербанк, а мои данные будут удалены из кадрового резерва ПАО Сбербанк и я осознаю последствия отзыва такого согласия.
      </p>
    </>
  );
};

export default SberAgreementContent;
