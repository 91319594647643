import React from 'react';

import * as s from '../../styles/agreement.module.scss';

const CherkizovoAgreementContent = () => {
  return (
    <>
      <h1 className={s.agreementTitle}>
        Согласие на обработку персональных данных
      </h1>

      <p className={s.agreementParagraph}>
        Нажимая кнопку <b>«я согласен»</b> я даю согласие Обществу с Ограниченной Ответственностью «РДВ-софт» (ИНН 7709969870, 109004, г. Москва, вн.тер.г. Муниципальный округ Таганский, пер. Малый Дровяной, д. 3, стр. 1, помещ. II, ком. 32) на обработку (сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение) и последующую передачу моих персональных данных Публичному акционерному обществу «Группа Черкизово»  (ИНН: 7718560636, место нахождения: 142931, Россия, Московская обл., г.о. Кашира, д. Топканово, тер. Мясопереработка, ул.  Черкизовская, д. 1, стр. 1) (далее – ПАО «Черкизово») для автоматизированной и неавтоматизированной обработки (запись, хранение, накопление, систематизация, использование, передача (предоставление, доступ), уточнение (обновление, изменение), блокирование, удаление и уничтожение) с целью рассмотрения моей кандидатуры для трудоустройства в ПАО «Черкизово», в т.ч. для учета в кадровом резерве.
      </p>

      <p className={s.agreementParagraph}>
        Мои персональные данные, в отношении которых дается согласие, включают:
      </p>

      <ul className={s.agreementList}>
        <li>фамилию, имя, отчество;</li>
        <li>дату и место рождения;</li>
        <li>сведения об образовании;</li>
        <li>сведения о текущем и предыдущих местах работы;</li>
        <li>адрес электронной почты;</li>
        <li>номер телефона (номера телефонов);</li>
        <li>и иные данные, предоставленные мной самостоятельно, либо лицом, рекомендовавшим мою кандидатуру на сайте https://cherkizovocareer.rabota.ru/.</li>
      </ul>

      <p className={s.agreementParagraph}>
        Согласие дается на срок, необходимый для рассмотрения моей кандидатуры и принятия решения о приеме меня на работу в ПАО «Черкизово» или включения в кадровый резерв. 
      </p>
      <p className={s.agreementParagraph}>
        Я оставляю за собой право отозвать согласие путем направления мной письменного уведомления в ООО «РДВ-софт» и ПАО «Черкизово».
      </p>
    </>
  );
};

export default CherkizovoAgreementContent;
