import React from 'react';

import * as s from '../../styles/agreement.module.scss';
import { useLinkToRabotaRu } from '../../helper/hooks';

const PAOSberbankAgreementContent = () => {
    const {
        hostNameRabotaRu
    } = useLinkToRabotaRu();

  return (
    <>
      <h1 className={s.agreementTitle}>
        Согласие на обработку персональных данных
      </h1>
      <p className={s.agreementParagraph}>
        Нажимая кнопку <b>«я согласен»</b>, я даю согласие Обществу с ограниченной ответственностью «РДВ-софт» (ИНН 7709969870, 109004, г. Москва, вн.тер.г. Муниципальный округ Таганский, пер. Малый Дровяной, д. 3, стр. 1, помещ. II, ком. 32) на обработку (сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение) моих персональных данных в целях направления мне Обществом с ограниченной ответственностью «РДВ-софт» информации, связанной с поиском работы, в том числе предложений, вакансий, приглашений на собеседования,  а также на передачу моих персональных данных Публичному акционерному обществу «Сбербанк России» (ИНН 7707083893,117312, г. Москва, ул. Вавилова, д. 19) (далее – ПАО Сбербанк) для автоматизированной и неавтоматизированной обработки (запись, хранение, накопление, систематизация, использование, передача (предоставление, доступ), уточнение (обновление, изменение), блокирование, удаление и уничтожение) с целью рассмотрения моей кандидатуры для трудоустройства в ПАО Сбербанк.
      </p>
      <p className={s.agreementParagraph}>
        Мои персональные данные, в отношении которых дается данное согласие, включают:
      </p>
      <ul className={s.agreementList}>
        <li>фамилию, имя, отчество;</li>
        <li>дату и место рождения;</li>
        <li>сведения об образовании</li>
        <li>сведения о текущем и предыдущих местах работы;</li>
        <li>адрес электронной почты;</li>
        <li>номер телефона (номера телефонов);</li>
        <li>и иные данные, предоставленные мной самостоятельно, либо лицом, рекомендовавшим мою кандидатуру на сайте <a href={`https://referalsber.${hostNameRabotaRu}/manager`} target='_blank' rel="noreferrer">https://referalsber.rabota.ru/manager</a>.</li>
      </ul>
      <p className={s.agreementParagraph}>
        Я согласен на передачу моих персональных данных ООО «САП СНГ» (ИНН 7705058323, 115054, г. Москва, Космодамианская наб., д. 52, стр.2), осуществляющим техническое сопровождение системы Пульс и обрабатывающим персональные данные по поручению ПАО «Сбербанк».
      </p>
      <p className={s.agreementParagraph}>
        Согласие ООО «РДВ-софт» дается сроком на пять лет.
      </p>
      <p className={s.agreementParagraph}>
        Согласие ПАО Сбербанк дается до заключения со мной трудового договора ПАО Сбербанк, либо до истечения 30 дней с момента принятия решения об отказе в приеме на работу.
      </p>
      <p className={s.agreementParagraph}>
        Я оставляю за собой право отозвать согласие:
      </p>
      <ul className={s.agreementList}>
        <li>на обработку моих персональных данных ООО «РДВ-софт» – путем направления мной письменного уведомления в ООО «РДВ-софт»;</li>
        <li>на обработку моих персональных данных ПАО Сбербанк – путем направления мной письменного уведомления в ПАО Сбербанк.</li>
      </ul>
    </>
  );
};

export default PAOSberbankAgreementContent;
