import React from 'react';
import PropTypes from 'prop-types';
import '../styles/light.scss';
import './header/header.module.scss';

import Seo from './seo';

const LightLayout = ({ children, actions, title }) => {

  return (
    <>
      <Seo
        title={title}
      />
      <div className="page-wrapper">
        <img
          className="logo"
          src="/images/logo.png"
          alt="СберПодбор"
        />
        <div className="content">
          {children}
        </div>
        {actions && <div className="actions">
          {actions}
        </div>}
      </div>
    </>
  );
};

LightLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default LightLayout;
