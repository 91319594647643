import React, { useState } from 'react';
import qs from 'query-string';

import LightLayout from '../components/lightLayout';
import NotFoundPage from '../pages/404';
import Button from '../components/button/button';

import GreenpeaceAgreementContent from '../components/agreement-contents/greenpeace';
import SberAgreementContent from '../components/agreement-contents/sber';
import CherkizovoAgreementContent from '../components/agreement-contents/cherkizovo';
import MegafonAgreementContent from '../components/agreement-contents/megafon';
import PAOSberbankAgreementContent from '../components/agreement-contents/PAOSberbank';

const AgreementPage = props => {
  const isServerSide = typeof window === 'undefined';
  const { search } = props.location;

  const { contentComponentName, title } = props.pageContext;
  const contentComponents = {
    GreenpeaceAgreementContent: GreenpeaceAgreementContent,
    SberAgreementContent: SberAgreementContent,
    CherkizovoAgreementContent: CherkizovoAgreementContent,
    MegafonAgreementContent: MegafonAgreementContent,
    PAOSberbankAgreementContent: PAOSberbankAgreementContent
  };
  const contentComponent = contentComponents[contentComponentName];

  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [agreement, setAgreement] = useState(null);

  const queries = qs.parse(search);
  const fetchAgreement = async () => {
    const apiPath = process.env.GATSBY_API_V2 + `public/applicant_profiles/${queries.hash}/agreement?include=company`;
    const response = await fetch(apiPath, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({ 'content-type': 'application/json' })
    });
    switch (response.status) {
      case 200: {
        setIsLoading(false);
        setAgreement(await response.json());
        break;
      }
      case 404: {
        setIsLoading(false);
        setNotFound(true);
        break;
      }
      default: {
        setIsLoading(false);
        break;
      }
    }
  };
  const updateAgreement =  async status => {
    const apiPath = process.env.GATSBY_API_V2 + `public/applicant_profiles/${queries.hash}/agreement`;
    const response = await fetch(apiPath, {
      method: 'PATCH',
      mode: 'cors',
      headers: new Headers({ 'content-type': 'application/vnd.api+json' }),
      body: JSON.stringify({
        data: {
          attributes: {
            type: status
          }
        }
      })
    });
    switch (response.status) {
      case 200: {
        setIsLoading(false);
        setAgreement(await response.json());
        fetchAgreement();
        break;
      }
      case 404: {
        setIsLoading(false);
        setNotFound(true);
        break;
      }
      default: {
        setIsLoading(false);
        fetchAgreement();
        break;
      }
    }
  };

  if (!isServerSide && !agreement) {
    fetchAgreement();
  }

  const renderActions = () => {
    return agreement?.data?.attributes?.agreementStatus === 'send' ?
      <>
        <Button
          text={'Я согласен'}
          onClick={() => updateAgreement('accepted')}
          noTitle
          newWindow
          medium
          round
        />

        <Button
          text={'Не согласен'}
          onClick={() => updateAgreement('rejected')}
          noTitle
          black
          medium
          round
          outline
          solid
        />
      </> : null;
  };
  const renderContent = () => {
    const status = agreement?.data?.attributes?.agreementStatus;

    if (status === 'accepted') {
      return <p>
        Вы дали согласие на обработку персональных данных. Обратитесь в техподдержку, если хотите отозвать согласие: support@sberpodbor.ru
      </p>;
    }

    if (status?.includes('rejected')) {
      return <p>
        Вы отказали в разрешении на обработку персональных данных
      </p>;
    }

    if (status === 'send') {
      return (
        React.createElement(contentComponent)
      );
    }

    return 'Произошла ошибка';
  };

  return (
    <>
      {isNotFound ?
        <NotFoundPage />
        :
        <LightLayout actions={renderActions()} title={title}>
          {isLoading ? 'Загрузка...' : renderContent()}
        </LightLayout>
      }
    </>
  );
};

export default AgreementPage;
