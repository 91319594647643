import React from 'react';
import Media from 'react-media';
import cn from 'classnames';

import Button from '../components/button/button';
import Layout from '../components/layout';
import Seo from '../components/seo';

import * as s from '../styles/404.module.scss';

const NotFoundPage = () => (
  <Layout isScroll={true} menuItems={[]}>
    <Seo title="404: Not found" />
    <div className={s.page404}>
      <div className={s.page404__inner}>
        <img src={'/images/page404.svg'} alt={''} className={s.page404__image}/>
        <div className={s.page404__content}>
          <h1 className={cn(s.page404__header, 'h1')}>
            404
          </h1>
          <div className={cn(s.page404__text, 'h2')}>
            Такой страницы не&nbsp;нашлось
          </div>
          <Media query={'(max-width: 768px)'} defaultMatches={true}>
            {
              match => (
                <Button
                  text={'Перейти на главную'}
                  href={'/'}
                  noTitle
                  black
                  round
                  small
                  outline
                />
              )
            }
          </Media>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
