import React from 'react';

import * as s from '../../styles/agreement.module.scss';
import { useLinkToRabotaRu } from '../../helper/hooks';

const MegafonAgreementContent = () => {
    const {
        hostNameRabotaRu
    } = useLinkToRabotaRu();
  return (
    <>
      <p className={s.agreementParagraph}>
        Нажимая кнопку <b>«я согласен»</b>, я даю согласие Обществу с ограниченной ответственностью «РДВ-софт» (ИНН 7709969870, 109004, г. Москва, вн.тер.г. Муниципальный округ Таганский, пер. Малый Дровяной, д. 3, стр. 1, помещ. II, ком. 32) на обработку (сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение) моих персональных данных в целях направления мне Обществом с ограниченной ответственностью «РДВ-софт» информации, связанной с поиском работы, в том числе предложений, вакансий, приглашений на собеседования,  а также на передачу моих персональных данных Публичному акционерному обществу «МегаФон»  (ИНН: 7812014560, адрес юридического лица: 127006, г. Москва, пер. Оружейный, д. 41) (далее – ПАО «МегаФон») для автоматизированной и неавтоматизированной обработки (запись, хранение, накопление, систематизация, использование, передача (предоставление, доступ), уточнение (обновление, изменение), блокирование, удаление и уничтожение) с целью рассмотрения моей кандидатуры для трудоустройства в ПАО «МегаФон».
      </p>
      <p className={s.agreementParagraph}>
        Мои персональные данные, в отношении которых дается согласие, включают:
      </p>
      <ul className={s.agreementList}>
        <li>фамилию, имя, отчество;</li>
        <li>дату и место рождения;</li>
        <li>сведения об образовании;</li>
        <li>сведения о текущем и предыдущих местах работы;</li>
        <li>адрес электронной почты;</li>
        <li>номер телефона (номера телефонов);</li>
        <li>и иные данные, предоставленные мной самостоятельно, либо лицом, рекомендовавшим мою кандидатуру на сайте <a href={`https://megafonvacancy.${hostNameRabotaRu}/`} target="_blank" rel="noreferrer">https://megafonvacancy.rabota.ru/</a>.</li>
      </ul>
      <p className={s.agreementParagraph}>
        Согласие ООО «РДВ-софт» дается на срок пять лет.
      </p>
      <p className={s.agreementParagraph}>
        Согласие ПАО «МегаФон» дается до заключения со мной трудового договора ПАО «Мегафон», либо до истечения 30 дней с момента принятия решения об отказе в приеме на работу.
      </p>
      <p className={s.agreementParagraph}>
        Я оставляю за собой право отозвать согласие:
      </p>
      <ul className={s.agreementList}>
        <li>на обработку моих персональных данных ООО «РДВ-софт» – путем направления мной письменного уведомления в ООО «РДВ-софт»;</li>
        <li>на обработку моих персональных данных ПАО «МегаФон» – путем направления мной письменного уведомления в ПАО «МегаФон».</li>
      </ul>
    </>
  );
};

export default MegafonAgreementContent;
